// TenantProvider.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { TenantContext } from './TenantContext';
import { useGetUser } from './UserContext';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { IUser, ITenantProfile } from '../types';

export const TenantProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tenantId, setTenantId] = useState<string | null>(null);
  const [tenantProfile, setTenantProfile] = useState<ITenantProfile | null>(null);
  const user = useGetUser();

  // Function to fetch and set the active tenant profile based on the tenant ID
  const fetchActiveTenantProfile = useCallback(async (userId: string, activeTenantId: string) => {
    const tenantProfileRef = doc(db, 'users', userId, 'tenantProfiles', activeTenantId);
    try {
      const tenantProfileSnapshot = await getDoc(tenantProfileRef);
      if (tenantProfileSnapshot.exists()) {
        setTenantProfile({
          id: tenantProfileSnapshot.id,
          ...tenantProfileSnapshot.data() as ITenantProfile,
        });
      } else {
        setTenantProfile(null); // Handle case where the tenant profile doesn't exist
      }
    } catch (error) {
      console.error('Error fetching tenant profile:', error);
      setTenantProfile(null); // Set to null on error to avoid incorrect data display
    }
  }, []);

  // Effect to fetch the tenant profile when tenantId or user changes
  useEffect(() => {
    if (user?.uid && tenantId) {
      fetchActiveTenantProfile(user.uid, tenantId);
    }
  }, [user?.uid, tenantId, fetchActiveTenantProfile]);

  // Effect to automatically set the first tenantId when the user logs in or their tenantIds change
  useEffect(() => {
    if (user?.tenantIds?.length > 0 && user.tenantIds[0] !== tenantId) {
      setTenantId(user.tenantIds[0]); // Set the first tenantId as active by default
    }
  }, [user?.tenantIds, tenantId]);

  const contextValue = {
    tenantId,
    setTenantId,
    tenantProfile,
    user
  };

  return (
    <TenantContext.Provider value={contextValue}>
      {children}
    </TenantContext.Provider>
  );
};
