import { useEffect, useState } from 'react';
import { Box, Spinner } from '@chakra-ui/react';
import { getDownloadURL, ref } from 'firebase/storage';
import { AnchorIcon } from '../../assets';
import { storage } from '../../firebase';

interface IProps {
  projectId: string;
}

export default function ProjectImage({ projectId }: IProps): JSX.Element {
  const [projectImageUrl, setProjectImageUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    async function fetchProjectImage() {
      setIsLoading(true);
      setError('');
      const imageRef = ref(storage, `projects/${projectId}.png`);
      try {
        const url = await getDownloadURL(imageRef);
        setProjectImageUrl(url);
      } catch (err) {
        setError('Failed to fetch project image.');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }
    
    fetchProjectImage();
  }, [projectId]);

  if (isLoading) {
    return (
      <Box
        h={150}
        bg="gray.200"
        rounded="sm"
        mb={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner color="blue.500" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        h={150}
        bg="gray.200"
        rounded="sm"
        mb={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <AnchorIcon fontSize="2xl" color="red.500" />
      </Box>
    );
  }

  return (
    <Box
      backgroundImage={`url(${projectImageUrl})`}
      backgroundSize="cover"
      backgroundPosition="center"
      m="auto"
      h={150}
      rounded="sm"
    />
  );
}
