import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, getDocs, getDoc, collection, collectionGroup, query, where, QueryDocumentSnapshot, onSnapshot } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { auth, db } from '../firebase';
import { IUser } from '../types';
import { transformDocToUser } from '../utils/user';

export interface IUserContext {
  currentUser: IUser | null;
  allUsers: IUser[];
  loading: boolean;
  error: Error | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  switchTenant: (newTenantId: string) => Promise<void>;
}

export const UserContext = createContext<IUserContext>({
  currentUser: null,
  allUsers: [],
  loading: false,
  error: null,
  login: async () => {},
  logout: async () => {},
  switchTenant: async () => {},
});

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [allUsers, setAllUsers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const [userDocs, setUserDocs] = useState<QueryDocumentSnapshot[] | null>(null);
  const [tenantProfileDocs, setTenantProfileDocs] = useState<QueryDocumentSnapshot[] | null>(null);

  useEffect(() => {
    if (userDocs === null || tenantProfileDocs === null) return;

    const tenantProfilesByUser = tenantProfileDocs.reduce<{ [key: string]: QueryDocumentSnapshot[] }>((carry, doc) => {
      const userId = doc.ref.parent.parent.id;
      if (carry.hasOwnProperty(userId)) carry[userId].push(doc);
      else carry[userId] = [doc];
      return carry;
    }, {});

    const users = userDocs
      .map(doc => transformDocToUser(doc, tenantProfilesByUser[doc.id] || []))
      .filter(user => user !== null);

    setAllUsers(users);
  }, [userDocs, tenantProfileDocs]);

  useEffect(() => {
    const userQuery = collection(db, 'users');
    const tenantProfileQuery = collectionGroup(db, 'tenantProfiles');

    const unsubscribeUsers = onSnapshot(userQuery, snapshot => setUserDocs(snapshot.docs));
    const unsubscribeTenantProfiles = onSnapshot(tenantProfileQuery, snapshot => setTenantProfileDocs(snapshot.docs));

    const unsubscribeAuth = onAuthStateChanged(auth, async (firebaseUser) => {
      setLoading(true);
      setError(null);
      if (firebaseUser) {
        try {
          const userRef = doc(db, 'users', firebaseUser.uid);
          const userTenantProfileRef = collection(db, `users/${firebaseUser.uid}/tenantProfiles`);
          const userDoc = await getDoc(userRef);
          const userTenantProfileDocs = await getDocs(userTenantProfileRef);
          if (userDoc.exists()) {
            const user = transformDocToUser(userDoc, userTenantProfileDocs.docs);
            setCurrentUser(user);
          } else {
            console.error('User does not exist in database.');
            setCurrentUser(null);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setError(error as Error);
        }
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return () => {
      unsubscribeAuth();
      unsubscribeUsers();
      unsubscribeTenantProfiles();
    };
  }, []);

  const login = async (email: string, password: string) => {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      setError(e as Error);
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    setLoading(true);
    try {
      await signOut(auth);
      setCurrentUser(null);
    } catch (e) {
      setError(e as Error);
    } finally {
      setLoading(false);
    }
  };

  const switchTenant = async (newTenantId: string) => {
    try {
      const result = await httpsCallable(getFunctions(), 'switchTenant')({ tenantId: newTenantId });
      console.log(result);
    } catch (error) {
      console.error('Error switching tenant:', error);
      setError(error as Error);
    }
  };

  const value = useMemo(() => ({
    currentUser,
    allUsers,
    loading,
    error,
    login,
    logout,
    switchTenant
  }), [currentUser, allUsers, loading, error]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export function useGetUser(): IUser | null {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useGetUser must be used within a UserProvider');
  }
  return context.currentUser;
}

export function useGetAllUsers(): IUser[] {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useGetAllUsers must be used within a UserProvider');
  }
  return context.allUsers;
}
