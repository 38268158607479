import { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { IWorkItem } from '../types';
export default function useFetchWorkItems(
  projectId?: string,
): [IWorkItem[], boolean, any] {  // Added 'any' to represent a potential error object
  const [workItems, setWorkItems] = useState<IWorkItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);  // State to handle errors

  useEffect(() => {
    async function fetchWorkItems() {
      if (!projectId) {
        setError('No project ID provided');
        setIsLoading(false);
        return;
      }

      const workItemsRef = collection(db, 'workItems');
      const q = query(workItemsRef, where('projectId', '==', projectId));

      try {
        const workItemDocs = await getDocs(q);
        const fetchedItems: IWorkItem[] = [];
        workItemDocs.forEach((doc) => {
          fetchedItems.push({
            id: doc.id,
            ...doc.data(),
          } as IWorkItem);
        });

        setWorkItems(fetchedItems);
      } catch (err) {
        setError(err);
      }
      setIsLoading(false);
    }

    fetchWorkItems();
  }, [projectId]);

  return [workItems, isLoading, error];
}
