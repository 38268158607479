// TenantContext.tsx
import { createContext, useContext } from 'react';
import { ITenantProfile, IUser } from '../types';

interface TenantContextType {
  tenantId: string | null;
  setTenantId: (tenantId: string | null) => void;
  tenantProfile: ITenantProfile | null;
  user: IUser | null;
}

const initialContextValue: TenantContextType = {
  tenantId: null,
  setTenantId: () => {},
  tenantProfile: null,
  user: null,
};

export const TenantContext = createContext<TenantContextType>(initialContextValue);

export const useTenant = () => useContext(TenantContext);
