import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import { useGetUser } from '../../contexts/'; // Make sure this import is correct
import {LogOutIcon } from '../../assets/'; // Update the import based on how you export this icon

export default function AvatarMenu(): JSX.Element {
  const navigate = useNavigate();
  const user = useGetUser(); // This should now correctly return IUser | null

  async function handleLogout() {
    try {
      await signOut(auth);
      navigate('/login'); // or whatever your login route is
    } catch (error) {
      console.error('Logout failed', error);
      // Optionally show a toast notification here
    }
  }

  function navigateToSettings() {
    navigate('/settings');
  }

  // Function to render user information
  function renderUserInfo() {
    if (user) {
      return (
        <>
          <Text fontWeight="bold" fontSize="sm">
            {`${user.firstName} ${user.lastName}`}
          </Text>
          <Text fontWeight="light" fontSize="xs" color="#9A9A9A">
            {user.role}
          </Text>
        </>
      );
    }
    return <Text fontWeight="bold" fontSize="sm">Guest</Text>;
  }
  return (
    <Menu>
      <MenuButton as={Box}>
        <Box display="flex" alignItems="center" gap={3}>
          <Box textAlign="left">{renderUserInfo()}</Box>
          <Avatar
            size="sm"
            name={`${user?.firstName} ${user?.lastName}`}
            src={user?.photoURL || undefined}
          />
        </Box>
      </MenuButton>
      <MenuList>
        <MenuItem
          fontSize="lg"
          display="flex"
          alignItems="center"
          gap={2}
          onClick={navigateToSettings}
          icon={<SettingsIcon />}
        >
          Settings
        </MenuItem>
        <MenuItem
          fontSize="lg"
          display="flex"
          alignItems="center"
          gap={2}
          onClick={handleLogout}
          icon={<LogOutIcon />} // Make sure LogOutIcon is imported correctly as a component
        >
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
}  