import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import App from './App';
import { ProjectsProvider, UserProvider } from './contexts';
import { theme } from './styles';
import reportWebVitals from './reportWebVitals';
import { PageErrorProvider } from './contexts/PageErrorContext';
import { TenantProvider } from './contexts/TenantProvider';
import { SupportDialogProvider } from './contexts/SupportDialogContext';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <UserProvider>
          <TenantProvider>
            <ProjectsProvider>
              <PageErrorProvider>
                <SupportDialogProvider>
                  <App />
                </SupportDialogProvider>
              </PageErrorProvider>
            </ProjectsProvider>
          </TenantProvider>
        </UserProvider>
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

reportWebVitals();