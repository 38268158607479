import React, { useState, useContext } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Textarea, useToast } from "@chakra-ui/react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { UserContext } from '../../contexts/UserContext';

interface SupportDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const SupportDialog: React.FC<SupportDialogProps> = ({ isOpen, onClose }) => {
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { currentUser } = useContext(UserContext);
  const toast = useToast();
  const functions = getFunctions(); 

  const sendMessage = async () => {
    if (!currentUser) {
      toast({
        title: "Authentication error.",
        description: "You must be logged in to send messages.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);
    const sendSupportEmail = httpsCallable(functions, "sendSupportEmail");
    try {
      await sendSupportEmail({ message, user_id: currentUser.uid, email: currentUser.email });
      toast({
        title: "Message sent successfully.",
        description: "We have received your message and will respond soon.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setMessage("");
      onClose();
    } catch (error) {
      console.error("Error sending message:", error);
      toast({
        title: "Error sending message.",
        description: "Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Keelblok Support</ModalHeader>
        <ModalBody>
          <Textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Your message for our support team..."
            size="sm"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            onClick={sendMessage}
            isLoading={isLoading}
            isDisabled={message.trim() === "" || !currentUser}
          >
            Send Message
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SupportDialog;
