import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { IPersonnel } from '../types';
import useFetchPersonnel from './useFetchPersonnel';

export default function useFetchPersonnelByWorkItem(workItemId: string): [IPersonnel[], boolean, string | undefined] {
  const [personnel, isLoading, error] = useFetchPersonnel();
  const [workItemTeam, setWorkItemTeam] = useState<IPersonnel[]>([]);

  useEffect(() => {
    if (!workItemId) return;
    const filteredTeam = personnel.filter(
      personnel => Array.isArray(personnel.workItems) && personnel.workItems.includes(workItemId)
    );
    setWorkItemTeam(filteredTeam);
  }, [personnel, workItemId]);

  return [workItemTeam, isLoading, error];
}
