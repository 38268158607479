import React, { useState, useContext } from 'react';
import {
  Box, Button, Drawer, DrawerBody, DrawerHeader, DrawerOverlay,
  DrawerContent, DrawerCloseButton, FormControl, FormLabel, useToast, Select
} from '@chakra-ui/react';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { IWorkItem } from '../../types';
import { UserContext } from '../../contexts/UserContext';
import { useFetchPersonnel } from '../../hooks';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  workItem: IWorkItem;
}

export default function AddPersonnelForm({
  isOpen,
  onClose,
  workItem,
}: IProps): JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { currentUser } = useContext(UserContext);
  const toast = useToast();

  // Use the useFetchPersonnel hook
  const [personnel, isLoading, error] = useFetchPersonnel();

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const selectedPersonnelId = (event.target as any).elements.personnel.value;
    if (!currentUser?.tenantId || !selectedPersonnelId) {
      toast({
        title: 'Error',
        description: 'Tenant ID or Personnel is not available.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
      return;
    }

    setIsSubmitting(true);

    // Fetch the selected personnel's tenant profile document ID
    const selectedPersonnel = personnel.find(p => p.uid === selectedPersonnelId);
    const tenantProfileId = selectedPersonnel ? selectedPersonnel.tenantId : null;

    if (!tenantProfileId) {
      toast({
        title: 'Error',
        description: 'Selected personnel does not have a valid tenant profile ID.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
      setIsSubmitting(false);
      return;
    }

    const personnelRef = doc(db, `users/${selectedPersonnelId}/tenantProfiles/${tenantProfileId}`);

    try {
      await updateDoc(personnelRef, {
        'personnel.workItems': arrayUnion(workItem.id),
        'personnel.projects': arrayUnion(workItem.projectId),
      });
      toast({
        title: 'Success',
        description: 'Personnel added to work item.',
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });
      onClose();
    } catch (error) {
      console.error('Error updating Firestore document:', error);
      toast({
        title: 'Uh Oh',
        description: 'An error occurred while adding personnel to work item.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add Personnel to Work Item</DrawerHeader>
        <DrawerBody>
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControl isRequired>
              <FormLabel fontSize="sm">Personnel</FormLabel>
              <Select
                placeholder="Select Personnel"
                name="personnel"
                isDisabled={isLoading || !!error}
              >
                {personnel && personnel.map((person) => (
                  <option key={person.uid} value={person.uid}>
                    {`${person.firstName} ${person.lastName} - ${person.trade}`}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Box display="flex" gap={3} justifyContent="flex-end" marginTop="auto" pb={4}>
              <Button variant="secondary" onClick={onClose} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button type="submit" variant="primary" isLoading={isSubmitting}>
                Add Personnel
              </Button>
            </Box>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
