import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from '@chakra-ui/react';

interface IProps {
  projectId?: string;
  workItemNumber: string;
}

export default function Breadcrumbs({
  projectId,
  workItemNumber,
}: IProps): JSX.Element {
  return (
    <Breadcrumb fontWeight="medium" fontSize="2xl">
      <BreadcrumbItem>
        <BreadcrumbLink as={Link} to={`/projects/${projectId}`} color="#00308C">
          Work Items
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem isCurrentPage>
        <Text>{workItemNumber}</Text>
      </BreadcrumbItem>
    </Breadcrumb>
  );
}
