import React, { useState, useEffect } from 'react';
import { Box, FormControl, FormLabel, Input, Switch, Button, useToast, Container, Heading } from '@chakra-ui/react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useGetUser } from '../../contexts/UserContext';
import { FirebaseError } from 'firebase/app';

// Define the structure of your tenant settings based on your Firestore document
interface TenantSettings {
  workspaceName: string;
  canPersonnelEditProfile: boolean;
  timezone: string; // Add other fields if necessary
}
const AdvancedWorkspaceSettings = () => {
  const user = useGetUser();
  const isTenantAdmin = user?.role === 'tenantAdmin';
  // Assume the first tenant ID is the current context for simplicity here
  const currentTenantId = user?.tenantId ? user.tenantId : null;
  const [tenantSettings, setTenantSettings] = useState<TenantSettings>({
    workspaceName: '',
    canPersonnelEditProfile: false,
    timezone: '', // Initialize with default values
  });
  const toast = useToast();

  useEffect(() => {
    if (!currentTenantId) {
      toast({ title: 'Error', description: 'No tenant ID found.', status: 'error' });
      return;
    }
    const tenantRef = doc(db, 'tenants', currentTenantId);
    const fetchTenantSettings = async () => {
      const tenantSnap = await getDoc(tenantRef);
      if (tenantSnap.exists()) {
        setTenantSettings(tenantSnap.data() as TenantSettings);
      } else {
        toast({ title: 'Error', description: 'Tenant settings not found.', status: 'error' });
      }
    };

    fetchTenantSettings();
  }, [currentTenantId]);

  const saveChanges = async () => {
    if (!currentTenantId) {
      toast({ title: 'Error', description: 'No tenant ID found to save settings.', status: 'error' });
      return;
    }
    const tenantRef = doc(db, 'tenants', currentTenantId);

    try {
      await updateDoc(tenantRef, { ...tenantSettings });
      toast({ title: 'Success', description: 'Workspace settings updated successfully.', status: 'success' });
    } catch (e) {
      const error = e as FirebaseError;
      toast({ title: 'Error', description: error.message || 'An error occurred while updating the workspace settings.', status: 'error' });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setTenantSettings((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  return (
    <Container maxW="1000px" py={4}>
      <Heading as="h2" size="lg" mb={6}>
        General
      </Heading>
      <Box>
        <FormControl id="workspace-name" mb="4">
          <FormLabel>Workspace Name</FormLabel>
          <Input
            type="text"
            name="workspaceName"
            value={tenantSettings.workspaceName}
            onChange={handleChange}
            isDisabled={!isTenantAdmin}
          />
        </FormControl>
        <FormControl display="flex" alignItems="center" mb="4">
          <FormLabel htmlFor="can-edit" mb="0">
            Allow Personnel to Edit Profile
          </FormLabel>
          <Switch
            id="can-edit"
            name="canPersonnelEditProfile"
            isChecked={tenantSettings.canPersonnelEditProfile}
            onChange={handleChange}
            isDisabled={!isTenantAdmin}
          />
        </FormControl>
        {/* Add the timezone FormControl here */}
        {/* Other settings... */}
        {isTenantAdmin && (
          <Button colorScheme="blue" mt="4" onClick={saveChanges}>Save Changes</Button>
        )}
      </Box>
    </Container>
  );
};

export default AdvancedWorkspaceSettings;