import React, { createContext, useContext, useEffect, useState } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { IProject } from '../types';
import { useGetUser } from './UserContext'; // Make sure the path is correct

const ProjectsContext = createContext<IProject[] | null>(null);

interface IProjectsProviderProps {
  children: React.ReactNode;
}

export const ProjectsProvider: React.FC<IProjectsProviderProps> = ({ children }) => {
  const [projects, setProjects] = useState<IProject[]>([]);
  const currentUser = useGetUser(); // This replaces useUserTenant

  useEffect(() => {
    // Check if the user is logged in and has a tenantId(s)
    if (!currentUser || !currentUser.tenantIds || currentUser.tenantIds.length === 0) {
      return;
    }

    // Assuming that the first tenantId is the active one
    const activeTenantId = currentUser.tenantIds[0];
    
    const projectsRef = collection(db, 'projects');
    const q = query(projectsRef, where('tenantId', '==', activeTenantId));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newProjects = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }) as IProject);

      setProjects(newProjects);
    });

    return () => unsubscribe();
  }, [currentUser]);

  return (
    <ProjectsContext.Provider value={projects}>
      {children}
    </ProjectsContext.Provider>
  );
};

export function useGetProjects() {
  const context = useContext(ProjectsContext);

  if (context === null) {
    throw new Error('useGetProjects must be used within a ProjectsProvider');
  }

  return context;
}
