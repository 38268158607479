import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading, Spinner, Alert, AlertIcon } from '@chakra-ui/react';
import { PersonnelTable } from '../../../components';
import useFetchPersonnelByProject from '../../../hooks/useFetchPersonnelByProject';

export default function ProjectTeam(): JSX.Element {
  const { id: projectId } = useParams<{ id: string }>();
  const [team, isLoading, error] = useFetchPersonnelByProject(projectId);

  if (isLoading) {
    console.log('Loading project team...');  // Logging loading state
    return (
      <Box display="flex" justifyContent="center" mt="20px">
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    console.error('Error loading project team:', error);  // Detailed console error
    return (
      <Alert status="error">
        <AlertIcon />
        There was an error loading the project team data: {error}. Please try again.
      </Alert>
    );
  }

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={6}>
        <Heading fontWeight="medium" fontSize="2xl">Project Team</Heading>
      </Box>
      <PersonnelTable personnel={team} showSearch={false} />
    </>
  );
}
