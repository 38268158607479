
import React, { useState } from 'react';
import { auth } from '../../firebase'; // Ensure this path is correct
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Container,
  Heading
} from '@chakra-ui/react';
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword } from 'firebase/auth';
import { FirebaseError } from "firebase/app";

const PasswordSettings: React.FC = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const toast = useToast();

  const handleChangePassword = async () => {
    const firebaseUser = auth.currentUser; // Get the currently signed-in user

    if (!firebaseUser || !firebaseUser.email) {
      toast({ title: 'Error', description: 'No user is currently logged in.', status: 'error' });
      return;
    }

    try {
      // Reauthenticate the user
      const credential = EmailAuthProvider.credential(
        firebaseUser.email,
        currentPassword
      );
      await reauthenticateWithCredential(firebaseUser, credential);
      
      // Update to the new password
      await updatePassword(firebaseUser, newPassword);
      toast({ title: 'Success', description: 'Password updated successfully.', status: 'success' });
    } catch (error) {
      let errorMessage = 'An error occurred while updating the password.';
      // Check if the error is a FirebaseError and has a message property
      if ((error as FirebaseError).message) {
        errorMessage = (error as FirebaseError).message;
      }
      toast({ title: 'Error', description: errorMessage, status: 'error' });
    }
  };
  

  return (
    <Container maxW="1000px" py={4}>
      <Heading as="h2" size="lg" mb={6}>
        Password
      </Heading>
      <Box>
        <FormControl id="current-password" isRequired mb="4">
          <FormLabel>Current Password</FormLabel>
          <Input
            type="password"
            name="currentPassword"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </FormControl>
        <FormControl id="new-password" isRequired mb="4">
          <FormLabel>New Password</FormLabel>
          <Input
            type="password"
            name="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </FormControl>
        <Button colorScheme="blue" mt="4" onClick={handleChangePassword}>
          Change Password
        </Button>
      </Box>
    </Container>
  );
};

export default PasswordSettings;