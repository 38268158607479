import React, { useEffect, useState, useContext, useRef } from 'react';
import { Box, Button, FormControl, Image, Input, Text, HStack, PinInput, PinInputField } from '@chakra-ui/react';
import { onAuthStateChanged, signInWithEmailAndPassword, PhoneAuthProvider, multiFactor, RecaptchaVerifier, MultiFactorResolver, MultiFactorError, PhoneMultiFactorGenerator, getMultiFactorResolver, sendEmailVerification } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase';
import keelblokLogo from '../../assets/keelblok-logo.svg';
import washingtonsHammerLogo from '../../assets/washingtons-hammer-logo.svg';
import { doc, getDoc } from 'firebase/firestore';
import { TenantContext } from '../../contexts/TenantContext';
import { Verifier } from '../../components/Verifier';

export default function LoginPage(): JSX.Element {
  const navigate = useNavigate();
  const { setTenantId } = useContext(TenantContext);

  // First Factor
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Second Factor
  const [MFAError, setMFAError] = useState<MultiFactorError | null>(null);
  const [verificationId, setVerificationId] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [verifier, setVerifier] = useState<RecaptchaVerifier | null>(null);
  const resolver = useRef<MultiFactorResolver | null>(null);

  // Enrollment State
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isEnrolling, setIsEnrolling] = useState<boolean>(false);
  const [enrollmentError, setEnrollmentError] = useState<string | null>(null);

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const mfaInfo = multiFactor(user);

        if (!user.emailVerified) {
          await sendEmailVerification(user);
          setErrorMessage("Please verify your email before enrolling in MFA. Check your email for the verification link.");
          setHasError(true);
          return;
        }

        if (!mfaInfo.enrolledFactors.length) {
          setIsEnrolling(true);
          return;
        }

        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const tenantIds = userDoc.data().tenantIds;
          const primaryTenantId = tenantIds?.length ? tenantIds[0] : null;
          setTenantId(primaryTenantId);
          if (primaryTenantId) {
            navigate('/projects');
          } else {
            setErrorMessage('No tenantId found for user.');
            setHasError(true);
          }
        } else {
          setErrorMessage('User document does not exist in Firestore.');
          setHasError(true);
        }
      }
    });

    return subscriber;
  }, [navigate, setTenantId]);

  async function handleLogin(event: React.SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    setHasError(false);
    setIsLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const mfaInfo = multiFactor(user);
      if (!mfaInfo.enrolledFactors.length) {
        if (!user.emailVerified) {
          await sendEmailVerification(user);
          setErrorMessage("Please verify your email before enrolling in MFA. Check your email for the verification link.");
          setHasError(true);
          return;
        }
        setIsEnrolling(true);
        return;
      }

      await handlePostLogin(userCredential);
    } catch (error: any) {
      if (error?.code === 'auth/multi-factor-auth-required') {
        handleMFAError(error);
      } else {
        console.error("Authentication error:", error);
        setErrorMessage(error.message || 'An unexpected error occurred. Please try again.');
        setHasError(true);
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function handleMFAError(error: MultiFactorError) {
    setMFAError(error);
    resolver.current = getMultiFactorResolver(auth, error);
    const hint = resolver.current.hints.shift();

    const phoneAuthProvider = new PhoneAuthProvider(auth);
    phoneAuthProvider.verifyPhoneNumber({ multiFactorHint: hint, session: resolver.current.session }, verifier)
      .then(verificationId => {
        console.log("Received verification ID:", verificationId);
        setVerificationId(verificationId);
      })
      .catch(err => {
        console.error('Failed to verify phone number for MFA:', err);
        setErrorMessage('Failed to send verification code. Please try again.');
      });
  }

  async function handlePostLogin(credential: any) {
    const user = credential.user;

    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const tenantIds = userDoc.data().tenantIds;
      const primaryTenantId = tenantIds?.length ? tenantIds[0] : null;
      setTenantId(primaryTenantId);

      if (primaryTenantId) {
        navigate('/projects');
      } else {
        setErrorMessage('No tenantId found for user.');
        setHasError(true);
      }
    } else {
      setErrorMessage('User document does not exist in Firestore.');
      setHasError(true);
    }
  }

  async function handleMFALogin(event: React.SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!verificationId || !verificationCode) return;

    try {
      console.log("Verification ID for MFA login:", verificationId);
      console.log("Verification Code for MFA login:", verificationCode);
      const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
      const assertion = PhoneMultiFactorGenerator.assertion(credential);
      const userCredential = await resolver.current.resolveSignIn(assertion);
      await handlePostLogin(userCredential);
    } catch (error) {
      console.error('Failed to complete MFA sign-in:', error);
      setErrorMessage('Failed to verify the code. Please try again.');
    }
  }

  async function handleMfaEnrollment(event: React.SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    setEnrollmentError(null);
    setIsLoading(true);

    const user = auth.currentUser;
    if (!user) return;

    try {
      const multiFactorSession = await multiFactor(user).getSession();
      const phoneInfoOptions = {
        phoneNumber: formatPhoneNumber(phoneNumber), // Format the phone number before sending it
        session: multiFactorSession,
      };

      const phoneAuthProvider = new PhoneAuthProvider(auth);
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, verifier);
      console.log("Verification ID for MFA enrollment:", verificationId);
      setVerificationId(verificationId);
    } catch (error) {
      console.error("Error during MFA enrollment:", error);
      setEnrollmentError("Failed to send verification code.");
    } finally {
      setIsLoading(false);
    }
  }

  async function verifyEnrollmentCode(event: React.SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    setEnrollmentError(null);
    setIsLoading(true);

    try {
      console.log("Verification ID for enrollment code verification:", verificationId);
      console.log("Verification Code for enrollment code verification:", verificationCode);
      const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credential);

      const user = auth.currentUser;
      if (!user) return;

      await multiFactor(user).enroll(multiFactorAssertion, "Personal phone number");
      console.log("Multi-factor enrollment successful.");
      setIsEnrolling(false);

      // Proceed with post-login actions
      await handlePostLogin({ user });
    } catch (error) {
      console.error("Error verifying enrollment code:", error);
      setEnrollmentError("Invalid verification code.");
    } finally {
      setIsLoading(false);
    }
  }

  function handleVerified(recaptchaVerifier: RecaptchaVerifier) {
    console.debug('reCAPTCHA verified, from LoginPage!');
    setVerifier(recaptchaVerifier);
  }

  function formatPhoneNumber(phoneNumber: string): string {
    // Format the phone number to +1 757-111-1111 format
    const cleaned = phoneNumber.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+${match[1]} ${match[2]}-${match[3]}-${match[4]}`;
    }
    return phoneNumber;
  }

  return (
    <Box
      bg="#F7F8FA"
      h="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box w="512px">
        <Image src={keelblokLogo} m="auto" mb={8} />

        <Box bg="white" shadow="md" p={10} mb={12}>
          <Text
            color="#00205B"
            fontSize="lg"
            fontWeight="medium"
            mb={6}
            textAlign="center"
          >
            Log in to your account
          </Text>

          {hasError && (
            <Text color="red" mb={4}>
              {errorMessage}
            </Text>
          )}

          <form onSubmit={handleLogin} style={{ display: (!isEnrolling && !MFAError) ? undefined : "none" }}>
            <FormControl isRequired isInvalid={hasError}>
              <Input
                placeholder="Email"
                type="email"
                mb={4}
                onChange={({ currentTarget }) => setEmail(currentTarget.value)}
              />
            </FormControl>

            <FormControl isRequired isInvalid={hasError}>
              <Input
                placeholder="Password"
                type="password"
                mb={4}
                onChange={({ currentTarget }) => setPassword(currentTarget.value)}
              />
            </FormControl>

            <Box>
              <Verifier onVerified={handleVerified} />
            </Box>

            <Button
              bg="#00205B"
              color="white"
              w="100%"
              type="submit"
              isLoading={isLoading}
              isDisabled={verifier === null}
            >
              Continue
            </Button>
          </form>

          {isEnrolling && (
            <Box>
              <Text color="#00205B" fontSize="lg" fontWeight="medium" mb={6} textAlign="center">
                Multi-Factor Authentication Enrollment
              </Text>

              <form onSubmit={handleMfaEnrollment}>
                <FormControl isRequired isInvalid={!!enrollmentError}>
                  <Input
                    placeholder="Phone Number"
                    type="tel"
                    mb={4}
                    onChange={({ currentTarget }) => setPhoneNumber(currentTarget.value)}
                  />
                  <Text fontSize="sm" color="gray.600">Phone number format: +1 757-111-1111</Text>
                  {enrollmentError && (
                    <Text color="red" mt={2}>
                      {enrollmentError}
                    </Text>
                  )}
                </FormControl>

                <Button
                  bg="#00205B"
                  color="white"
                  w="100%"
                  type="submit"
                  isLoading={isLoading}
                  isDisabled={verifier === null}
                >
                  Send Verification Code
                </Button>
              </form>

              {verificationId && (
                <form onSubmit={verifyEnrollmentCode}>
                  <FormControl isRequired isInvalid={!!enrollmentError} mt={4}>
                    <HStack>
                      <PinInput onChange={(value) => setVerificationCode(value)}>
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                      </PinInput>
                    </HStack>
                  </FormControl>

                  <Button
                    bg="#00205B"
                    color="white"
                    w="100%"
                    type="submit"
                    isLoading={isLoading}
                    isDisabled={!verificationCode}
                  >
                    Verify Code
                  </Button>
                </form>
              )}
            </Box>
          )}

          {MFAError && !isEnrolling && (
            <Box>
              <Text color="#00205B" fontSize="lg" fontWeight="medium" mb={6} textAlign="center">
                Multi-Factor Authentication Required
              </Text>

              <form onSubmit={handleMFALogin}>
                <FormControl isRequired>
                  <HStack>
                    <PinInput onChange={(value) => setVerificationCode(value)}>
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                </FormControl>

                <Button
                  bg="#00205B"
                  color="white"
                  w="100%"
                  type="submit"
                  isLoading={isLoading}
                  isDisabled={!verificationCode}
                >
                  Verify Code
                </Button>
              </form>
            </Box>
          )}
        </Box>

        <Box w="85%" m="auto" borderTop="1px solid" color="#C0BFBF" pt={8}>
          <Image src={washingtonsHammerLogo} m="auto" />
        </Box>
      </Box>
    </Box>
  );
}
