import { Box, Image, Link } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import AvatarMenu from './AvatarMenu';
import keelblockLogo from '../../assets/keelblok-logo.svg';
import { ProjectsIcon, UsersIcon } from '../../assets/';

export default function Header(): JSX.Element {
  return (
    <Box
      bg="white"
      px={10}
      py={3}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      h="60px"
      borderBottom="1px solid"
      borderColor="#CCCCCC"
    >
      <Box display="flex" gap={12}>
        <Image src={keelblockLogo} h="30px" />

        <Box display="flex" gap={6} fontSize="sm" color="navyBlue">
          <Link
            as={NavLink}
            to="/projects"
            display="flex"
            alignItems="center"
            gap={1}
            _activeLink={{
              fontWeight: 'bold',
            }}
          >
            <ProjectsIcon />
            Projects
          </Link>

          <Link
            as={NavLink}
            to="/personnel"
            display="flex"
            alignItems="center"
            gap={1}
            _activeLink={{
              fontWeight: 'bold',
            }}
          >
            <UsersIcon />
            Personnel
          </Link>
        </Box>
      </Box>

      <AvatarMenu />
    </Box>
  );
}
