import { useEffect, useState } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { ITask } from '../types';

export default function useFetchTasksByProject(
  projectId?: string,
): [ITask[], boolean] {
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!projectId) {
      return;
    }

    const tasksRef = collection(db, 'tasks');
    const q = query(tasksRef, where('projectId', '==', projectId));

    const unsubscribe = onSnapshot(q, (tasksDocs) => {
      const newTasks: ITask[] = [];
      tasksDocs.forEach((doc) => {
        newTasks.push({
          id: doc.id,
          ...doc.data(),
        } as ITask);
      });

      newTasks.sort((a, b) => {
        return a.number - b.number;
      });

      setIsLoading(false);
      setTasks(newTasks);
    });

    return () => unsubscribe();
  }, [projectId]);

  return [tasks, isLoading];
}
