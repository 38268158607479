import { useEffect, useState, useContext } from 'react';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { TenantContext } from '../contexts/TenantContext';
import { IProject } from '../types';

export default function useFetchProjects(): [IProject[], boolean, string | undefined] {
  const { tenantId } = useContext(TenantContext);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    async function fetchProjects() {
      if (!tenantId) {
        setIsLoading(false);
        setError('Tenant ID is undefined');
        return;
      }

      setIsLoading(true);
      try {
        const projectQuery = query(collection(db, 'projects'), where('tenantId', '==', tenantId));
        const projectSnapshot = await getDocs(projectQuery);
        const fetchedProjects = projectSnapshot.docs.map(doc => ({
          id: doc.id,
          ...(doc.data() as IProject),
        }));
        setProjects(fetchedProjects);
      } catch (error) {
        console.error("Error fetching projects:", error);
        setError('Failed to fetch projects');
      } finally {
        setIsLoading(false);
      }
    }

    fetchProjects();
  }, [tenantId]);

  return [projects, isLoading, error];
}
