import React, { useState, useContext } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormErrorMessage,
  useToast,
  Spinner
} from '@chakra-ui/react';
import RoleDropdown from '../RoleDropdown';
import { ROLES } from '../../constants';
import { auth } from '../../firebase';
import { TenantContext } from '../../contexts/TenantContext';



interface InviteUserFormProps {
  isOpen: boolean;
  onClose: () => void;
}

const InviteUserForm: React.FC<InviteUserFormProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [errors, setErrors] = useState({ email: '', firstName: '', lastName: '', role: '' });
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { tenantId } = useContext(TenantContext);


  const validateForm = () => {
    const newErrors = { email: '', firstName: '', lastName: '', role: '' };
    let isValid = true;
    if (!email) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email is invalid';
      isValid = false;
    }
    if (!firstName) {
      newErrors.firstName = 'First name is required';
      isValid = false;
    }
    if (!lastName) {
      newErrors.lastName = 'Last name is required';
      isValid = false;
    }
    if (!role) {
      newErrors.role = 'Role is required';
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    console.log("handleSubmit called");
    if (validateForm()) {
      setIsSubmitting(true);
      const functionUrl = "https://us-central1-keelblok-staging.cloudfunctions.net/createUserViaForm";
      try {
        const idToken = await auth.currentUser?.getIdToken(true);
  
        if (!idToken) throw new Error("Unable to authenticate the user. No token found.");
  
        const response = await fetch(functionUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${idToken}`
          },
          body: JSON.stringify({ email, firstName, lastName, role, tenantId })
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
  
        const userDocument = await response.json();
        toast({
          title: "User Invited",
          description: "User has been successfully invited.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
  
        onClose();
      } catch (error) {
        let message = "Unable to invite the user at this time.";
        if (error instanceof Error) {
          console.error("Submission error:", error); // Log the error for debugging.
          message = error.message; // Use the error message from the exception if available.
        }
        toast({
          title: "Invitation Failed",
          description: message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite New User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Form Controls */}
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </FormControl>
          <FormControl>
            <FormLabel>First Name</FormLabel>
            <Input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
          </FormControl>
          <FormControl>
            <FormLabel>Last Name</FormLabel>
            <Input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
          </FormControl>
          <RoleDropdown
            label="Role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            isEditable={true}
            roles={ROLES}
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit} isDisabled={isSubmitting}>
            {isSubmitting ? <Spinner size="sm" /> : 'Invite User'}
          </Button>
          <Button variant="ghost" onClick={onClose} isDisabled={isSubmitting}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InviteUserForm;
