import React, { useState, useEffect } from 'react';
import { Box, Avatar, Text, Button, Flex, useToast, Container, Heading, Alert, AlertIcon, AlertDescription } from '@chakra-ui/react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { useGetUser } from '../../contexts/UserContext';
import { IUser } from '../../types';
import InviteUserForm from './InviteUserForm';
import EditUserForm from './EditUserForm';
import PasswordResetField from '../PasswordResetField';


const TeamManagementSettings = () => {
  const user = useGetUser();
  const isTenantAdmin = user?.role === 'tenantAdmin';
  const tenantId = user?.tenantId;
  const [usersList, setUsersList] = useState<IUser[]>([]);
  const [editUser, setEditUser] = useState<IUser | null>(null);
  const [isEditUserFormOpen, setIsEditUserFormOpen] = useState(false);
  const [isInviteUserFormOpen, setIsInviteUserFormOpen] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (tenantId && isTenantAdmin) {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where("tenantIds", "array-contains", tenantId));
      getDocs(q).then(querySnapshot => {
        const users = querySnapshot.docs.map(doc => {
          const userData = doc.data() as IUser;
          return userData;
        });
        setUsersList(users);
      }).catch(error => {
        toast({
          title: 'Error fetching users',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
    }
  }, [tenantId, isTenantAdmin, toast]);

  const handleEditUser = (userItem: IUser) => {
    setEditUser(userItem);
    setIsEditUserFormOpen(true);
  };

  const handleInviteUser = () => {
    setIsInviteUserFormOpen(true);
  };

  const handleOffboardUser = (userId: string) => {
    console.log('Offboard user:', userId);
    // Here, add your logic to offboard a user
  };

  if (!isTenantAdmin) {
    return (
      <Alert status="info">
        <AlertIcon />
        <AlertDescription>You do not have permission to view this page.</AlertDescription>
      </Alert>
    );
  }

  return (
    <Container maxW="1000px" py={4}>
      <Heading as="h2" size="lg" mb={6}>Team Management</Heading>
      <Flex direction="column" gap="4" mb={4}>
        <Button colorScheme="green" onClick={handleInviteUser}>Invite User</Button>
      </Flex>

      {isInviteUserFormOpen && (
        <InviteUserForm
          isOpen={isInviteUserFormOpen}
          onClose={() => setIsInviteUserFormOpen(false)}
        />
      )}

      {editUser && (
        <EditUserForm
          isOpen={isEditUserFormOpen}
          onClose={() => setIsEditUserFormOpen(false)}
          user={editUser}
        />
      )}

      {usersList.map(userItem => (
        <Flex key={userItem.uid} align="center" p="2" borderWidth="1px" borderRadius="md" mb={4}>
          <Avatar size="md" name={`${userItem.firstName} ${userItem.lastName}`} src={userItem.photoURL || undefined} mr="4" />
          <Box flex="1" mr="4">
            <Text fontWeight="bold">{`${userItem.firstName} ${userItem.lastName}`}</Text>
            <Text fontSize="sm">{userItem.email}</Text>
          </Box>
          <Button size="sm" colorScheme="blue" onClick={() => handleEditUser(userItem)} mr="2">Edit</Button>
          <Button size="sm" colorScheme="red" onClick={() => handleOffboardUser(userItem.uid)} width="auto" px={2}>Offboard</Button>
          <PasswordResetField email={userItem.email} />
        </Flex>
      ))}
    </Container>
  );
};

export default TeamManagementSettings;
