import React, { createContext, useState, ReactNode } from 'react';

interface PageErrorContextState {
  error: Error | null;
  setError: (error: Error | null) => void;
}

// Provide a default value that matches the context shape
const defaultContextValue: PageErrorContextState = {
  error: null,
  setError: () => {}, // No-op default function
};

// Export the context so it can be used by other components
export const PageErrorContext = createContext<PageErrorContextState>(defaultContextValue);

interface PageErrorProviderProps {
  children: ReactNode; // ReactNode type includes null, so you don't need '| null'
}

export const PageErrorProvider: React.FC<PageErrorProviderProps> = ({ children }) => {
  const [error, setError] = useState<Error | null>(null);

  const value: PageErrorContextState = { error, setError };

  return (
    <PageErrorContext.Provider value={value}>
      {children}
    </PageErrorContext.Provider>
  );
};

export function usePageError() {
  const context = React.useContext(PageErrorContext);
  if (!context) { // If context is null or undefined, throw an error
    throw new Error('usePageError must be used within a PageErrorProvider');
  }
  return context;
}
