import { useNavigate } from 'react-router-dom';
import { Box, Heading } from '@chakra-ui/react';
import { StatusBadge, WorkItemProgressBar } from '../../../components';
import { IWorkItem } from '../../../types';

interface IProps {
  projectId?: string;
  workItem: IWorkItem;
}

export default function WorkItemCard({
  projectId,
  workItem,
}: IProps): JSX.Element {
  const navigate = useNavigate();

  function handleClick() {
    navigate(`/projects/${projectId}/items/${workItem.id}`);
  }

  return (
    <Box
      shadow="md"
      rounded="sm"
      bg="white"
      cursor="pointer"
      _hover={{ shadow: 'xl' }}
      onClick={handleClick}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="#CCCCCC"
        px={4}
        py={2}
      >
        <Heading fontSize="sm" fontWeight="medium" my={4}>
          {workItem.number}
        </Heading>

        <StatusBadge status={workItem.status} />
      </Box>

      <Box px={4} py={2}>
        <WorkItemProgressBar id={workItem.id} />
      </Box>
    </Box>
  );
}
