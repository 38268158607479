import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Table,
  Text,
  Thead,
  Tbody,
  Td,
  Tr,
  Th,
  TableContainer,
  useToast,
} from '@chakra-ui/react';
import { arrayRemove, doc, updateDoc } from 'firebase/firestore';
import PersonnelTableSearch from './PersonnelTableSearch';
import PersonnelAssignment from './PersonnelAssignment';
import PersonnelWorkItemAssignment from './PersonnelWorkItemAssignment';
import { EditPersonnelForm, TradeBadge } from '../';
import { filterPersonnel } from './utils';
import { Edit } from '../../assets';
import { IPersonnel, IWorkItem, LeadTrade } from '../../types';
import { db } from '../../firebase';
import UserAvatar from '../User/UserAvatar';

interface IProps {
  personnel: IPersonnel[];
  showAssignment?: boolean;
  showEditAction?: boolean;
  showRemoveAction?: boolean;
  showSearch?: boolean;
  workItem?: IWorkItem;
  workItems?: IWorkItem[];
}

export default function PersonnelTable({
  personnel,
  showAssignment = false,
  showEditAction = false,
  showRemoveAction = false,
  showSearch = true,
  workItem,
  workItems,
}: IProps): JSX.Element {
  const [editPersonnel, setEditPersonnel] = useState<IPersonnel | undefined>();
  const [filteredPersonnel, setFilteredPersonnel] =
    useState<IPersonnel[]>(personnel);
  const toast = useToast();

  useEffect(() => {
    setFilteredPersonnel(personnel);
  }, [personnel]);

  function handleSearch(searchValue: string, tradeFilter?: LeadTrade) {
    const filteredPersonnel = filterPersonnel({
      personnel,
      searchValue,
      tradeFilter,
    });

    setFilteredPersonnel(filteredPersonnel);
  }

  async function handleRemove(personnelId: string): Promise<void> {
    const personnelRef = doc(db, `personnel/${personnelId}`);

    try {
      await updateDoc(personnelRef, {
        workItems: arrayRemove(workItem?.id),
        projects: arrayRemove(workItem?.projectId),
      });
    } catch (error) {
      toast({
        title: 'Uh Oh',
        description:
          'An error occurred while removing personnel from the work item.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    }
  }

  return (
    <Box>
      {editPersonnel && (
        <EditPersonnelForm
          personnel={editPersonnel}
          isOpen
          onClose={() => setEditPersonnel(undefined)}
        />
      )}

      {showSearch && <PersonnelTableSearch onChange={handleSearch} />}

      <Text mb={6} fontSize="sm">
        Showing <b>{filteredPersonnel.length}</b> personnel
      </Text>

      <TableContainer bg="white" rounded="md" shadow="md">
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Trade</Th>
              <Th>Badge Number</Th>
              <Th>Rate</Th>
              {(showAssignment || workItems) && <Th>Assignment</Th>}
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {filteredPersonnel.map((person) => (
              <Tr key={person.uid}>
                <Td display="flex" alignItems="center" gap={2}>
                  <UserAvatar
                    id={person.uid}
                    name={`${person.firstName} ${person.lastName}`}
                    trade={person.trade}
                    size="xs"
                  />
                  {person.firstName} {person.lastName}
                </Td>
                <Td>
                  <TradeBadge trade={person.trade} />
                </Td>
                <Td>{person.badgeNumber}</Td>
                <Td textTransform="capitalize">{person.rate}</Td>
                {showAssignment && (
                  <PersonnelAssignment projectIds={person.projects} />
                )}
                {workItems && (
                  <PersonnelWorkItemAssignment
                    workItems={workItems}
                    workItemIds={person.workItems}
                  />
                )}
                <Td>
                  {showRemoveAction && (
                    <Button
                      size="xs"
                      colorScheme="red"
                      onClick={() => handleRemove(person.uid)}
                    >
                      Remove
                    </Button>
                  )}
                  {showEditAction && (
                    <Button
                      leftIcon={<Edit />}
                      size="xs"
                      variant="outline"
                      onClick={() => setEditPersonnel(person)}
                    >
                      Edit
                    </Button>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}