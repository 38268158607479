import { IPersonnel, LeadTrade } from '../../types';

interface IFilterPersonnelParams {
  personnel: IPersonnel[];
  searchValue: string;
  tradeFilter?: LeadTrade;
}

export function filterPersonnel({
  personnel,
  searchValue,
  tradeFilter,
}: IFilterPersonnelParams): IPersonnel[] {
  return personnel
    .filter((person) => {
      if (searchValue) {
        return `${person.firstName} ${person.lastName}`.toLowerCase().includes(searchValue);
      } else {
        return person;
      }
    })
    .filter((person) => {
      if (tradeFilter) {
        return person.trade === tradeFilter;
      } else {
        return person;
      }
    });
}