import React from 'react';
import { Box, Button, Image, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import notFoundImage from '../../assets/notfound.webp'; // Update with your 404 image asset path

export default function NotFoundPage(): JSX.Element {
  const navigate = useNavigate();

  return (
    <Box
      bg="#F7F8FA"
      h="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Image src={notFoundImage} m="auto" mb={8} />
      <Text color="#00205B" fontSize="xl" fontWeight="medium" mb={6} textAlign="center">
        Oops! We can't seem to find the page you're looking for.
      </Text>
      <Button
        bg="#00205B"
        color="white"
        w="fit-content"
        p={6}
        onClick={() => navigate('/')}
      >
        Go Back Home
      </Button>
    </Box>
  );
}
