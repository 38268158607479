// src/contexts/SupportDialogContext.tsx

import React, { createContext, useContext, useState, ReactNode } from 'react';

interface SupportDialogContextType {
  isOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
}

const SupportDialogContext = createContext<SupportDialogContextType | null>(null);

export const useSupportDialog = () => {
  const context = useContext(SupportDialogContext);
  if (context === null) throw new Error('useSupportDialog must be used within a SupportDialogProvider');
  return context;
}

interface SupportDialogProviderProps {
  children: ReactNode; // This is the correct type for children in TypeScript
}

export const SupportDialogProvider: React.FC<SupportDialogProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  return (
    <SupportDialogContext.Provider value={{ isOpen, openDialog, closeDialog }}>
      {children}
    </SupportDialogContext.Provider>
  );
};
