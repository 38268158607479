import { Box, Image } from '@chakra-ui/react';
import Header from './Header';
import washingtonsHammerLogo from '../../assets/washingtons-hammer-logo.svg';

interface IProps {
  children: React.ReactNode;
  hasSidebar?: boolean;
}

export default function PageLayout({
  children,
  hasSidebar = false,
}: IProps): JSX.Element {
  return (
    <Box bg="#F7F8FA" minH="100vh">
      <Header />

      <Box
        py={hasSidebar ? undefined : 4}
        px={hasSidebar ? undefined : 6}
        display="grid"
        gridTemplateRows="auto min-content"
        minH="calc(100vh - 60px)"
      >
        {children}

        {!hasSidebar && (
          <Box pt={4}>
            <Image src={washingtonsHammerLogo} margin="auto" maxW={9} />
          </Box>
        )}
      </Box>
    </Box>
  );
}
