import { useEffect, useState } from 'react';
import { Avatar } from '@chakra-ui/react';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../firebase';
import { LeadTrade } from '../../types';
import { TRADE_COLORS } from '../../constants';

interface IProps {
  bg?: string;
  id: string;
  name: string;
  size?: string;
  trade: LeadTrade;
}

export default function UserAvatar({
  bg,
  id,
  size = 'md',
  name,
  trade
}: IProps): JSX.Element {
  const [personnelImageUrl, setPersonnelImageUrl] = useState<string>('');

  useEffect(() => {
    async function fetchPersonnelImage() {
        const storageRef = ref(storage, `users/${id}`);
        
        try {
            const url = await getDownloadURL(storageRef);
            setPersonnelImageUrl(url);
        } catch {
            setPersonnelImageUrl('');
        }
    }

    fetchPersonnelImage();
  }, [id]);

  const backgroundColor = TRADE_COLORS[trade];

  return (
    <Avatar
      size={size}
      name={name}
      src={bg || personnelImageUrl}
      bg={backgroundColor}
      color="white"
    />
  );
}
