import React, { useState } from 'react';
import { Box, VStack, Button, Divider, Icon, useColorModeValue, Text } from '@chakra-ui/react';
import PageLayout from '../PageLayout';
import ProfileSettings from '../ProfileSettings';
import AdvancedWorkspaceSettings from '../AdvancedWorkspaceSettings';
import PasswordSettings from '../PasswordSettings';
import { useGetUser } from '../../contexts/UserContext';
import {
  FaUser,     // For profile icon
  FaKey,      // For password icon
  FaCog,      // For general settings icon
  FaUsers,    // For team members icon
  FaBuilding, // For workspace icon
  FaUserTie   // For personal icon
} from 'react-icons/fa';
import TeamManagementSettings from '../TeamManagementSettings';

const SettingsPage: React.FC = () => {
  const [activeSetting, setActiveSetting] = useState<string>('profile');
  const user = useGetUser();
  const isTenantAdmin = user?.role === 'tenantAdmin';

  const sidebarColor = useColorModeValue('gray.200', 'gray.600'); // Lighter sidebar color
  const headerTextColor = useColorModeValue('blue.600', 'blue.300'); // Stand-out color for headers

  // Define the structure of navigation items, including headers which are not clickable
  const navigationItems = [
    ...(isTenantAdmin ? [
      { label: 'Workspace', icon: <Icon as={FaBuilding} />, isHeader: true },
      { label: 'General', icon: <Icon as={FaCog} />, isHeader: false },
      { label: 'Team members', icon: <Icon as={FaUsers} />, isHeader: false },
      { label: 'Divider', isDivider: true },
    ] : []),
    { label: 'Personal', icon: <Icon as={FaUserTie} />, isHeader: true },
    { label: 'Profile', icon: <Icon as={FaUser} />, isHeader: false },
    { label: 'Password', icon: <Icon as={FaKey} />, isHeader: false },
  ];

  const renderActiveSettingComponent = () => {
    switch (activeSetting) {
      case 'profile':
        return <ProfileSettings isEditable={isTenantAdmin} />;
      case 'password':
        return <PasswordSettings />;
      case 'general':
        return isTenantAdmin ? <AdvancedWorkspaceSettings /> : null;
      case 'teammembers':
        return isTenantAdmin ? <TeamManagementSettings /> : null;
      // No need for a separate case for RoleDropdown if it's part of ProfileSettings
      default:
        return null;
    }
  };

  return (
    <PageLayout hasSidebar={true}>
      <Box as="main" display="flex">
        <Box as="aside" width="256px" p="4" bg={sidebarColor}>
          <VStack align="stretch" spacing={4}>
            {navigationItems.map(({ label, icon, isHeader, isDivider }) => (
              isDivider ? <Divider my={4} key={label} /> : isHeader ? (
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  color={headerTextColor}
                  px={3}
                  py={1}
                  key={label}
                >
                  {label}
                </Text>
              ) : (
                <Button
                  key={label}
                  leftIcon={icon}
                  justifyContent="flex-start"
                  variant="ghost"
                  bg={activeSetting === label.toLowerCase().replace(/\s+/g, '') ? 'gray.500' : 'inherit'} // Highlight for the active setting
                  _hover={{
                    bg: useColorModeValue('gray.300', 'gray.500'), // Hover color
                  }}
                  onClick={() => setActiveSetting(label.toLowerCase().replace(/\s+/g, ''))}
                  isDisabled={isHeader}
                >
                  {label}
                </Button>
              )
            ))}
          </VStack>
        </Box>
        <Box flex="1" p="4">
          {renderActiveSettingComponent()}
        </Box>
      </Box>
    </PageLayout>
  );
};

export default SettingsPage;
