import { ChangeEvent, useState, SyntheticEvent, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Image,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Timestamp, addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import dayjs from 'dayjs';
import { db, storage } from '../../firebase';
import { UserContext } from '../../contexts/UserContext';
import { IProject, ItemStatus } from '../../types';
import { TenantContext } from '../../contexts/TenantContext';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function CreateProjectForm({ isOpen, onClose }: IProps): JSX.Element {
  const [name, setName] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [customer, setCustomer] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const toast = useToast();
  const navigate = useNavigate();
  const { tenantId } = useContext(TenantContext);
  const { currentUser } = useContext(UserContext);

  async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsSubmitting(true);

    if (!currentUser || !tenantId) {
      toast({
        title: 'Error',
        description: 'User not found. Please login again.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
      setIsSubmitting(false);
      return;
    }

    const collectionRef = collection(db, 'projects');

    try {
      const { id } = await addDoc(collectionRef, {
        createdAt: new Date(),
        createdBy: currentUser.uid,
        tenantId: tenantId,
        name,
        number,
        location,
        customer,
        startDate: formatDate(startDate),
        status: ItemStatus.NOT_STARTED,
        endDate: formatDate(endDate),
      } as Omit<IProject, 'id' | 'createdAt'>);

      if (file) {
        const fileName = `${id}.png`;
        const storageRef = ref(storage, `projects/${fileName}`);
        await uploadBytes(storageRef, file);
        const fileUrl = await getDownloadURL(storageRef);
        await updateDoc(doc(db, 'projects', id), { imageUrl: fileUrl });
      }

      toast({
        title: 'Success',
        description: 'Project successfully created.',
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });

      navigate(`/projects/${id}`);
    } catch (error) {
      setIsSubmitting(false);
      toast({
        title: 'Error',
        description: 'An error occurred while creating the project.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    }
  }

  function handleImageUpload(event: ChangeEvent<HTMLInputElement>) {
    const fileUpload = event.target.files?.[0];

    if (fileUpload) {
      const imageSrc = URL.createObjectURL(fileUpload);
      setFile(fileUpload);
      setImagePreviewUrl(imageSrc);
    }
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Create Project</DrawerHeader>

        <DrawerBody>
          <form onSubmit={handleSubmit} style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
            <VStack spacing={4} mb={4}>
              <FormControl>
                <FormLabel>Project Name</FormLabel>
                <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter project name" />
              </FormControl>
              <FormControl>
                <FormLabel>Project Number</FormLabel>
                <Input value={number} onChange={(e) => setNumber(e.target.value)} placeholder="Enter project number" />
              </FormControl>
              <FormControl>
                <FormLabel>Location</FormLabel>
                <Input value={location} onChange={(e) => setLocation(e.target.value)} placeholder="Enter location" />
              </FormControl>
              <FormControl>
                <FormLabel>Customer</FormLabel>
                <Input value={customer} onChange={(e) => setCustomer(e.target.value)} placeholder="Enter customer name" />
              </FormControl>
              <FormControl>
                <FormLabel>Start Date</FormLabel>
                <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
              </FormControl>
              <FormControl>
                <FormLabel>End Date</FormLabel>
                <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
              </FormControl>
              <FormControl>
                <FormLabel>Upload Image</FormLabel>
                <input type="file" onChange={handleImageUpload} />
                {imagePreviewUrl && <Image src={imagePreviewUrl} alt="Preview" />}
              </FormControl>
            </VStack>

            <Box display="flex" gap={3} justifyContent="flex-end">
              <Button variant="secondary" onClick={onClose} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button type="submit" variant="primary" isLoading={isSubmitting}>
                Save Project
              </Button>
            </Box>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

function formatDate(date: string): Timestamp {
  const now = dayjs();
  return Timestamp.fromDate(
    dayjs(date).set('hour', now.hour()).set('minute', now.minute()).toDate(),
  );
}
