import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
} from '@chakra-ui/react';
import { Search } from '../../assets';
import { LeadTrade } from '../../types';

interface IProps {
  onChange: (searchValue: string, tradeFilter?: LeadTrade) => void;
}

export default function PersonnelTableSearch({
  onChange,
}: IProps): JSX.Element {
  const [searchValue, setSearchValue] = useState<string>('');
  const [tradeFilter, setTradeFilter] = useState<LeadTrade | undefined>();

  function handleClearFilters() {
    setSearchValue('');
    setTradeFilter(undefined);
  }

  useEffect(() => {
    onChange(searchValue, tradeFilter);
  }, [searchValue, tradeFilter]);

  return (
    <Box display="flex" alignItems="center" gap={2} mb={2}>
      <InputGroup maxWidth={275}>
        <InputLeftElement pointerEvents="none">
          <Search color="gray.300" />
        </InputLeftElement>
        <Input
          value={searchValue}
          placeholder="Search Personnel by Name"
          bg="white"
          onChange={({ currentTarget }) =>
            setSearchValue(currentTarget.value.toLowerCase())
          }
        />
      </InputGroup>
      <Select
        bg="white"
        w={200}
        value={tradeFilter || 'DEFAULT'}
        onChange={({ currentTarget }) =>
          setTradeFilter(currentTarget.value as LeadTrade)
        }
      >
        <option value="DEFAULT">Filter by Trade</option>
        {Object.values(LeadTrade).map((trade) => (
          <option value={trade} key={trade}>
            {trade.charAt(0).toUpperCase()}
            {trade.slice(1)}
          </option>
        ))}
      </Select>
      {(searchValue || tradeFilter) && (
        <Button variant="ghost" size="xs" onClick={handleClearFilters}>
          Clear filters
        </Button>
      )}
    </Box>
  );
}
