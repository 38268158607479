// App.tsx
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import ProjectsPage from './pages/ProjectsPage';
import ProjectPage from './pages/ProjectPage';
import PersonnelPage from './pages/PersonnelPage';
import SettingsPage from './components/SettingsPage';
import NotFoundPage from './pages/NotFoundPage';
import { ProtectedRoute } from './components';
import { useSupportDialog } from './contexts/SupportDialogContext';
import SupportDialog from './components/SupportDialog';
import SupportFAB from './components/SupportFab';

const App: React.FC = () => {
  const { isOpen, openDialog, closeDialog } = useSupportDialog();

  return (
    <>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="/projects" element={<ProtectedRoute><ProjectsPage /></ProtectedRoute>} />
        <Route path="/projects/:id/*" element={<ProtectedRoute><ProjectPage /></ProtectedRoute>} />
        <Route path="/personnel" element={<ProtectedRoute><PersonnelPage /></ProtectedRoute>} />
        <Route path="/settings" element={<ProtectedRoute><SettingsPage /></ProtectedRoute>} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <SupportFAB onOpen={openDialog} />
      <SupportDialog isOpen={isOpen} onClose={closeDialog} />
    </>
  );
};

export default App;
