import { useContext, useEffect, useState } from 'react';
import { useGetUser } from '../contexts';
import { IPersonnel } from '../types';
import { UserContext } from '../contexts/UserContext';

export default function useFetchPersonnel(): [IPersonnel[], boolean, string?] {
  const user = useGetUser();
  const [personnel, setPersonnel] = useState<IPersonnel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const { allUsers } = useContext(UserContext);

  useEffect(() => {
    try {
      const personnelUsers = allUsers.filter(user => user.tenantProfile.role === 'personnel');

      const personnelData: IPersonnel[] = personnelUsers.map(user => ({
        ...user,
        ...user.tenantProfile.personnel
      }));

      setPersonnel(personnelData);
      setIsLoading(false);
    } catch (err) {
      console.error("Error fetching personnel:", err);
      setError('Failed to fetch personnel data.');
      setIsLoading(false);
    }
  }, [user.tenantId, allUsers]);

  return [personnel, isLoading, error];
}
