import { Box, Button, Heading, useDisclosure } from '@chakra-ui/react';
import {
  CreateProjectForm,
  EmptyState,
  Loader,
  PageLayout,
} from '../../components';
import ProjectCard from './ProjectCard';
import { useFetchProjects } from '../../hooks';
import { PlusIcon } from '../../assets';

export default function ProjectsPage(): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [projects, isLoading] = useFetchProjects();

  return (
    <PageLayout>
      <CreateProjectForm isOpen={isOpen} onClose={onClose} />

      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={6}
        >
          <Heading fontWeight="medium" fontSize="2xl">
            Projects
          </Heading>
          <Button leftIcon={<PlusIcon />} variant="primary" onClick={onOpen}>
            Create Project
          </Button>
        </Box>

        {isLoading ? (
          <Loader />
        ) : (
          <Box
            display="grid"
            gridTemplateColumns="repeat(auto-fill, minmax(250px, 1fr))"
            gap={8}
            flexWrap="wrap"
          >
            {projects.map((project) => (
              <ProjectCard key={project.id} project={project} />
            ))}
          </Box>
        )}

        {!isLoading && projects.length === 0 && (
          <EmptyState
            description="Create a project to get started with Keelblok."
            heading="No Projects"
          />
        )}
      </Box>
    </PageLayout>
  );
}
