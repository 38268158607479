import { Link, Td } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { IWorkItem } from '../../types';

interface IProps {
  workItems: IWorkItem[];
  workItemIds?: string[];
}

export default function PersonnelWorkItemAssignment({
  workItems,
  workItemIds,
}: IProps): JSX.Element {
  return (
    <Td>
      {(!workItemIds || workItemIds.length === 0) && 'No Work Items'}

      {workItemIds?.map((workItemId) => {
        const workItem = workItems.find(
          (workItem) => workItem.id === workItemId,
        );
        return (
          <Link
            as={ReactRouterLink}
            key={workItemId}
            to={`/projects/${workItem?.projectId}/items/${workItemId}`}
            textDecoration="underline"
          >
            {workItem?.number}
          </Link>
        );
      })}
    </Td>
  );
}
