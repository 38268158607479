import React, { useState, SyntheticEvent } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { IUser } from '../../types'; 
import RoleDropdown from '../RoleDropdown';
import { ROLES } from '../../constants';



interface IProps {
  isOpen: boolean;
  onClose: () => void;
  user: IUser; 
}

const EditUserForm: React.FC<IProps> = ({ isOpen, onClose, user }) => {
  const [firstName, setFirstName] = useState<string>(user.firstName);
  const [lastName, setLastName] = useState<string>(user.lastName);
  const [email, setEmail] = useState<string>(user.email);
  const [role, setRole] = useState<string>(user.role);
  

  const toast = useToast();

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    try {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        firstName,
        lastName,
        email,
        role,
        // Don't forget to handle other fields like tenantId 
      });

      toast({
        title: 'Success',
        description: 'User details updated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      onClose(); // Close the drawer
    } catch (error: any) {
      toast({
        title: 'Error',
        description: error.message || 'An error occurred while updating the user.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Edit User Details</DrawerHeader>

        <DrawerBody>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>First Name</FormLabel>
                <Input
                  value={firstName}
                  onChange={(e) => setFirstName(e.currentTarget.value)}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Last Name</FormLabel>
                <Input
                  value={lastName}
                  onChange={(e) => setLastName(e.currentTarget.value)}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
              </FormControl>

              <FormControl isRequired>
              <RoleDropdown
            label="Role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            isEditable={true}
            roles={ROLES}
          />
              </FormControl>

              {/* Add more fields as needed */}
            </VStack>

            <Box mt={4}>
              <Button colorScheme="blue" mr={3} type="submit">
                Save Changes
              </Button>
              <Button variant="ghost" onClick={onClose}>Cancel</Button>
            </Box>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default EditUserForm;
