import { Timestamp } from 'firebase/firestore';

export interface ITenant {
  id: string;
  name: string;
  createdAt: Timestamp;
}

export interface IUser {
  uid: string; // The user's document ID in Firestore
  email: string;
  firstName: string;
  lastName: string;
  role: string; // User's global role across the application
  tenantId: string; //Active tenant ID, injected on the frontend
  tenantProfile: ITenantProfile;
  tenantProfiles: {
    [key: string]: ITenantProfile;
  };
  tenantIds?: string[]; // The active tenant ID, injected on the frontend
  isActive: boolean;
  deletedAt: Timestamp | null;
  createdAt: Timestamp;
  photoURL?: string;

}

export interface ITenantProfile {
  id: string; // This could be the document ID of the tenant profile in Firestore
  role: string; // Tenant-specific role
  personnel?: IPersonnelData; // Optional, as there might be tenants where the user doesn't have personnel data
}

export interface IPersonnel {
  uid: string;
  firstName: string;
  lastName: string;
  badgeNumber: string;
  createdAt: Timestamp;
  tenantId: string;
  projects: string[];
  rate: PersonnelRate;
  trade: LeadTrade;
  workItems: string[];
}

export interface IPersonnelData {
  badgeNumber: string;
  rate: PersonnelRate; // You can use PersonnelRate enum or string if the values match the Firestore data
  trade: LeadTrade; // You can use LeadTrade enum or string if the values match the Firestore data
  projects: string[]; // IDs of projects
  workItems: string[]; // IDs of work items
  name: string;
}

export interface IProject {
  id: string;
  name: string;
  number: string;
  location: string;
  customer: string;
  startDate: Timestamp;
  endDate: Timestamp;
  createdBy: string;
  createdAt: Timestamp;
  tenantId: string;
  status: ItemStatus;
}



export enum ItemStatus {
  IN_PROGRESS = 'in_progress',
  NOT_STARTED = 'not_started',
  COMPLETE = 'complete',
  SUSPENDED = 'suspended',
}

export interface IWorkItem {
  id: string;
  tenantId: string;
  consumables: string[];
  createdAt: Timestamp;
  createdBy: string;
  materials: string[];
  number: string;
  projectId: string;
  requiredTools: string[];
  status: ItemStatus;
}

export interface ITask {
  id: string;
  tenantId: string;
  createdAt: Timestamp;
  createdBy: string;
  name: string;
  number: number;
  leadTrade: LeadTrade;
  hoursAllocated: number;
  progress: number;
  projectId: string;
  status: ItemStatus;
  subtasks: ISubtask[];
  workItemId: string;
}

export interface ISubtask {
  id: string;
  name: string;
  status: ItemStatus;
}

export enum LeadTrade {
  SHIPFITTER = 'SHIPFITTER',
  WELDER = 'WELDER',
  MACHINIST = 'MACHINIST',
  ELECTRICIAN = 'ELECTRICIAN',
  PIPEFITTER = 'PIPEFITTER',
  SHEETMETAL = 'SHEETMETAL',
  INSULATOR = 'INSULATOR',
  PAINTER = 'PAINTER',
  RIGGER = 'RIGGER',
  SCAFFOLDING_BUILDER = 'SCAFFOLDING_BUILDER',
  FIREWATCH = 'FIREWATCH',
}

export enum PersonnelRate {
  SUPERVISOR = 'SUPERVISOR',
  CREW_LEADER = 'CREW_LEADER',
  SPECIALIST = 'SPECIALIST',
  FIRST_CLASS = 'FIRST_CLASS',
  SECOND_CLASS = 'SECOND_CLASS',
  THIRD_CLASS = 'THIRD_CLASS',
  HANDY_PERSON = 'HANDY_PERSON',
  HELPER = 'HELPER',
  LABORER = 'LABORER',
}

export interface ProfileSettingsProps {
  isEditable: boolean;
}

export interface RoleDropdownProps {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  isEditable: boolean;
  roles: string[];
}

export { Timestamp } from 'firebase/firestore';
