import { Box, Button, Heading } from '@chakra-ui/react';
import { EmptyState, Loader, PageLayout, PersonnelTable } from '../../components';
import { PlusIcon } from '../../assets';
import { useFetchPersonnel } from '../../hooks';
import { useNavigate } from 'react-router-dom';

export default function PersonnelPage(): JSX.Element {
  const [personnel, isLoading, error] = useFetchPersonnel();
  const navigate = useNavigate();

  if (error) {
    console.error("Rendering error on Personnel Page:", error);
    return (
      <PageLayout>
        <EmptyState description={error} heading="Error loading personnel" />
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={6}>
          <Heading fontWeight="medium" fontSize="2xl">Personnel</Heading>
          <Button leftIcon={<PlusIcon />} variant="primary" onClick={() => navigate('/settings')}>
            Invite Personnel
          </Button>
        </Box>

        {isLoading ? <Loader /> : personnel.length === 0 ? (
          <EmptyState description="Begin by creating new personnel." heading="No Personnel" />
        ) : (
          <PersonnelTable personnel={personnel} showAssignment showEditAction />
        )}
      </Box>
    </PageLayout>
  );
}
