import { Box, Button, Heading, Link, useDisclosure } from '@chakra-ui/react';
import { doc, updateDoc } from 'firebase/firestore';
import { NavLink as ReactRouterLink } from 'react-router-dom';
import {
  EditProjectForm,
  ProjectDetails,
  ProjectImage,
  ProjectProgressCircle,
  StatusMenu,
} from '../../components';
import { Edit, GridIcon, UsersIcon } from '../../assets';
import { useSubscribeToProject } from '../../hooks';
import { ItemStatus } from '../../types';
import { db } from '../../firebase';

interface IProps {
  projectId?: string;
}

export default function ProjectSidebar({ projectId }: IProps): JSX.Element {
  const [project] = useSubscribeToProject(projectId);
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!project) {
    // @TODO: Handle sidebar loading
    return <Box />;
  }

  async function handleStatusChange(status: ItemStatus) {
    if (!project) {
      return;
    }

    const projectRef = doc(db, 'projects', project?.id);

    await updateDoc(projectRef, {
      status,
    });
  }

  return (
    <>
      <EditProjectForm isOpen={isOpen} onClose={onClose} project={project} />

      <Box
        display="flex"
        flexDir="column"
        alignItems="center"
        borderRight="1px solid"
        borderColor="#CCCCCC"
      >
        <Box p={5} w="100%">
          <ProjectImage projectId={project.id} />

          <Heading fontSize="lg" fontWeight="normal" mt={4}>
            {project.name}
          </Heading>
        </Box>

        <Box w="100%" color="navyBlue" fontSize="sm">
          <Link
            as={ReactRouterLink}
            to={`/projects/${projectId}`}
            end
            display="flex"
            alignItems="center"
            gap={2}
            pl={5}
            py={2}
            _activeLink={{
              bg: 'navyBlue',
              color: 'white',
            }}
          >
            <GridIcon /> Work Items
          </Link>

          <Link
            as={ReactRouterLink}
            to={`/projects/${projectId}/team`}
            display="flex"
            alignItems="center"
            gap={2}
            pl={5}
            py={2}
            _activeLink={{
              bg: 'navyBlue',
              color: 'white',
            }}
          >
            <UsersIcon />
            Project Team
          </Link>
        </Box>

        <Box borderTop="1px solid" borderColor="#CCCCCC" w="100%" my={4} />

        <Box w="100%" px={5}>
          <Box mb={6}>
            <Heading size="xs" w="100%" mb={2}>
              Status
            </Heading>
            <StatusMenu
              status={project.status}
              onChange={handleStatusChange}
              hasBorder
            />
          </Box>

          <Box mb={6}>
            <Heading size="xs" w="100%" mb={2}>
              Progress
            </Heading>
            <Box display="flex" justifyContent="center">
              <ProjectProgressCircle id={project.id} />
            </Box>
          </Box>

          <Box mb={6}>
            <Heading size="xs" w="100%" mb={2}>
              Details
            </Heading>
            <ProjectDetails project={project} />
          </Box>

          <Heading size="xs" w="100%" mb={2}>
            Actions
          </Heading>

          <Button
            leftIcon={<Edit />}
            size="sm"
            w="100%"
            variant="outline"
            onClick={onOpen}
          >
            Edit Project
          </Button>
        </Box>
      </Box>
    </>
  );
}
