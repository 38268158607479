import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { AddPersonnelForm, PersonnelTable } from '../../../../components';
import { PlusIcon } from '../../../../assets';
import { IWorkItem, ITenantProfile } from '../../../../types';
import { useFetchPersonnelByWorkItem } from '../../../../hooks';

interface IProps {
  workItem: IWorkItem;
}

export default function PersonnelTab({ workItem }: IProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [personnel] = useFetchPersonnelByWorkItem(workItem.id);

  return (
    <Box>
      <AddPersonnelForm isOpen={isOpen} onClose={onClose} workItem={workItem} />

      <Box display="flex" justifyContent="flex-end" mb={6}>
        <Button leftIcon={<PlusIcon />} variant="primary" onClick={onOpen}>
          Add Personnel
        </Button>
      </Box>

      <PersonnelTable
        personnel={personnel}
        showRemoveAction={true}
        showSearch={false}
        workItem={workItem}
      />
    </Box>
  );
}
