import React, { useRef, useEffect, useState } from 'react';
import { RecaptchaVerifier } from '@firebase/auth';
import { auth } from '../firebase';

type Props = {
    onVerified: (verfier?: RecaptchaVerifier, widgetId?: number, response?: any) => void
};

export const Verifier: React.FC<Props> = ({ onVerified }) => {

    const [id, setId] = useState<number|null>(null);
    const verifier = useRef<RecaptchaVerifier|null>(null);

    useEffect(() => {

        console.debug('Initializing reCAPTCHA...');
        
        if (verifier.current === null) {
            verifier.current = new RecaptchaVerifier('recaptcha', {
                size: 'normal',
                callback(response: any) {
                    onVerified(verifier.current, id, response);
                }
            }, auth);
            verifier.current.render().then(id => setId(id));
        }
        
        return () => {
            console.debug('Cleaning up reCAPTCHA...');
            if (verifier.current !== null) {
                verifier.current.clear();
                verifier.current = null;
                console.debug('Destroyed reCAPTCHA!');
            }
        }

    }, []);

    return <div id="recaptcha"/>

} 

export default RecaptchaVerifier;