import { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { Box, Button, Text, Tooltip, VStack } from '@chakra-ui/react';
import { StatusMenu } from '../';
import ProgressInput from './ProgressInput';
import { ChevronDown, ChevronUp, Edit } from '../../assets';
import { ITask, ItemStatus } from '../../types';
import { db } from '../../firebase';

interface IProps {
  idx: number;
  onEdit: (task: ITask) => void;
  task: ITask;
}

export default function TaskRow({ idx, onEdit, task }: IProps): JSX.Element {
  const [showSubtasks, setShowSubtasks] = useState<boolean>(false);
  const hasSubtasks = task.subtasks?.length > 0;

  async function updateTaskStatus(status: ItemStatus) {
    const taskRef = doc(db, 'tasks', task.id);

    await updateDoc(taskRef, {
      status,
    });
  }

  async function updateSubtaskStatus(idx: number, status: ItemStatus) {
    const taskRef = doc(db, 'tasks', task.id);
    const subtasksToUpdate = [...task.subtasks];
    subtasksToUpdate[idx].status = status;

    await updateDoc(taskRef, {
      subtasks: subtasksToUpdate,
    });
  }

  return (
    <Box
      border="1px solid"
      borderColor="gray.200"
      rounded="md"
      padding={2}
      mb={2}
    >
      <Box
        alignItems="center"
        display="grid"
        gridTemplateColumns="300px 130px 125px 125px auto min-content"
        fontSize="sm"
      >
        <Box
          onClick={() => hasSubtasks && setShowSubtasks(!showSubtasks)}
          cursor={hasSubtasks ? 'pointer' : ''}
          display="flex"
          alignItems="center"
        >
          <Text fontWeight="bold" mr={1}>
            {task.number}.
          </Text>

          <Tooltip label={task.name}>
            <Text
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              w="250px"
            >
              {task.name}
              <div />
            </Text>
          </Tooltip>

          {hasSubtasks && (
            <Box display="inline" fontSize="sm">
              {showSubtasks ? <ChevronUp /> : <ChevronDown />}
            </Box>
          )}
        </Box>

        <Box ml={-1}>
          <StatusMenu status={task.status} onChange={updateTaskStatus} />
        </Box>

        <ProgressInput progress={task.progress} taskId={task.id} />

        <Box>{task.hoursAllocated}</Box>

        <Box textTransform="capitalize">{task.leadTrade}</Box>

        <Button
          leftIcon={<Edit />}
          size="xs"
          variant="outline"
          onClick={() => onEdit(task)}
        >
          Edit
        </Button>
      </Box>

      {showSubtasks && (
        <VStack pl={8} mt={4} alignItems="flex-start" spacing={2}>
          {task.subtasks.map((subtask, idx) => (
            <Box
              alignItems="center"
              display="grid"
              gridTemplateColumns="252px auto"
              gap={2}
              key={idx}
              fontSize="sm"
            >
              <Box display="flex">
                <Text fontWeight="bold" mr={1}>
                  {task.number}.{idx + 1}
                </Text>

                <Tooltip label={subtask.name}>
                  <Text
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    w="252px"
                  >
                    {subtask.name}
                  </Text>
                </Tooltip>
              </Box>

              <StatusMenu
                status={subtask.status}
                onChange={(status) => updateSubtaskStatus(idx, status)}
              />
            </Box>
          ))}
        </VStack>
      )}
    </Box>
  );
}
