import { Box, Button, Heading, useDisclosure } from '@chakra-ui/react';
import { doc, updateDoc } from 'firebase/firestore';
import {
  EmptyState,
  Loader,
  StatusMenu,
  TaskForm,
  TasksTable,
  WorkItemProgressBar,
} from '../../../../components';
import { PlusIcon } from '../../../../assets';
import { useFetchTasksByWorkItem } from '../../../../hooks';
import { IWorkItem, ItemStatus } from '../../../../types';
import { db } from '../../../../firebase';

interface IProps {
  workItem: IWorkItem;
}

export default function WorkBreakdownTab({ workItem }: IProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tasks, isLoading] = useFetchTasksByWorkItem(workItem.id);

  if (isLoading) {
    return <Loader />;
  }

  async function handleStatusChange(status: ItemStatus) {
    const workItemRef = doc(db, 'workItems', workItem.id);

    await updateDoc(workItemRef, {
      status,
    });
  }

  return (
    <Box>
      <Box w={256} mb={6}>
        <Box mb={6}>
          <Heading size="sm" fontWeight="medium" mb={2}>
            Status
          </Heading>

          <StatusMenu
            status={workItem.status}
            onChange={handleStatusChange}
            hasBorder
          />
        </Box>

        <Heading size="sm" fontWeight="medium" mb={2}>
          Progress
        </Heading>

        <Box mt={2}>
          <WorkItemProgressBar id={workItem.id} />
        </Box>
      </Box>

      <TaskForm
        isOpen={isOpen}
        onClose={onClose}
        projectId={workItem.projectId}
        workItemId={workItem.id}
      />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={6}
      >
        <Heading size="sm" fontWeight="medium">
          Tasks
        </Heading>

        <Button leftIcon={<PlusIcon />} variant="primary" onClick={onOpen}>
          Create Task
        </Button>
      </Box>

      {tasks.length === 0 ? (
        <EmptyState
          description="Begin by creating a task."
          heading="No Tasks"
        />
      ) : (
        <TasksTable tasks={tasks} />
      )}
    </Box>
  );
}
