// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// Refer to the Firebase documentation for the required import statements and initialization
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDgqSVJBHQd_6mJXU8S6D5PfcromPcjIKw",
  authDomain: "keelblok-staging.appspot.com",
  projectId: "keelblok-staging",
  storageBucket: "keelblok-staging.appspot.com",
  messagingSenderId: "259364545851",
  appId: "1:259364545851:web:a5e460ab9411762a55031e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

// Initialize Firebase Storage and get a reference to the service
const storage = getStorage(app);

const functions = getFunctions(app);

// Export the Firebase services to use throughout your application
export { auth, db, storage, functions };
