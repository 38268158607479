import { Icon } from '@chakra-ui/react';

export default function Personnel(props: any): JSX.Element {
  return (
    <Icon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.9114 21.9631L23.3112 20.3268C22.2627 17.4702 19.5535 15.458 16.3832 15.0735C15.2072 16.2713 13.6763 17.0024 11.9994 17.0024C10.323 17.0024 8.79247 16.2713 7.61637 15.0735C4.4465 15.458 1.73733 17.4702 0.688795 20.3268L0.0886064 21.9631C-0.084171 22.4337 -0.00138061 22.9524 0.311706 23.3567C0.624409 23.7606 1.12857 24.0001 1.66563 24.0001H22.3343C22.8714 24.0001 23.3756 23.7606 23.6882 23.3567C24.0014 22.9524 24.0842 22.4337 23.9114 21.9631Z"
          fill="currentColor"
        />
        <path
          d="M11.9985 15.7896C14.9248 15.7896 17.3003 12.914 17.3805 9.33142H6.61768C6.69788 12.914 9.07342 15.7896 11.9985 15.7896Z"
          fill="currentColor"
        />
        <path
          d="M5.41289 7.74309C5.58781 7.82292 5.78146 7.87183 5.98962 7.87183H17.9327H18.0095C18.2173 7.87183 18.4114 7.82292 18.5859 7.74309C19.0193 7.54572 19.32 7.1367 19.32 6.65903C19.32 5.98913 18.7334 5.44629 18.0096 5.44629H17.9064H17.769C17.7316 5.27058 17.6872 5.09695 17.6398 4.92398C17.2823 3.62084 16.6369 2.40851 15.7292 1.50127V2.38207C15.7292 2.82852 15.338 3.19056 14.8556 3.19056C14.3731 3.19056 13.982 2.82852 13.982 2.38207V0.309071C13.6342 0.164628 13.2618 0.0646715 12.8727 0V3.26362C12.8727 3.71007 12.4816 4.07212 11.9991 4.07212C11.5167 4.07212 11.1255 3.71007 11.1255 3.26362V0C10.7365 0.0646715 10.3645 0.164628 10.0168 0.308664V2.38207C10.0168 2.82852 9.62563 3.19056 9.14323 3.19056C8.66083 3.19056 8.26962 2.82852 8.26962 2.38207V1.50133C7.36227 2.40815 6.71726 3.61967 6.35989 4.9221C6.3121 5.09578 6.26771 5.26987 6.23018 5.44634H6.0924H5.98962C5.26571 5.44634 4.6792 5.98918 4.6792 6.65908C4.67914 7.13634 4.97987 7.54537 5.41289 7.74309Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
