import React from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase'; // Make sure this is the correct path
import { Button, Input, useToast, Stack } from '@chakra-ui/react';

interface PasswordResetFieldProps {
  email: string;
}

const PasswordResetField: React.FC<PasswordResetFieldProps> = ({ email }) => {
  const toast = useToast();

  const handlePasswordReset = async () => {
    // Check if the email is valid
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      toast({
        title: 'Invalid Email',
        description: "Please provide a valid email address.",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    // Continue with sending the password reset email
    try {
      await sendPasswordResetEmail(auth, email.trim());
      toast({
        title: 'Password Reset',
        description: `Password reset email sent to ${email}`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      let errorMessage = 'Failed to send password reset email';
      if (error instanceof Error) errorMessage = error.message;
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };


  return (
    <Stack direction="row" spacing={2}> {/* Adjust the spacing here to match your design */}
      {/* The Input component is read-only and should not affect spacing */}
      <Input placeholder="Password" value="••••••••" readOnly type="password" mx={4} />
      <Button onClick={handlePasswordReset} colorScheme="blue" width="auto" px={8}>
        Reset Password
      </Button>
    </Stack>
  );
};

export default PasswordResetField;
