import React, { ChangeEvent, useState, useEffect, SyntheticEvent } from 'react';
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    FormControl,
    FormLabel,
    Input,
    useToast,
    VStack,
    Select,
} from '@chakra-ui/react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { LeadTrade, IPersonnel, PersonnelRate } from '../../types';
import UserAvatar from '../User/UserAvatar';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    personnel: IPersonnel;
}

export default function EditPersonnelForm({
    isOpen,
    onClose,
    personnel,
}: IProps): JSX.Element | null { // Indicate that this component might return null
    const [badgeNumber, setBadgeNumber] = useState<string>(personnel.badgeNumber);
    const [trade, setTrade] = useState<string>(personnel.trade);
    const [rate, setRate] = useState<string>(personnel.rate);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string>('');

    const toast = useToast();

    async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
        event.preventDefault();

        setIsSubmitting(true);

        try {
            const profileRef = doc(db, 'users', personnel.uid, 'tenantProfiles', personnel.tenantId);

            await updateDoc(profileRef, {
                personnel: {
                    badgeNumber: badgeNumber,
                    rate,
                    trade,
                } as Pick<IPersonnel, 'badgeNumber' | 'rate' | 'trade'>
            });

            toast({
                title: 'Success',
                description: 'Personnel record updated.',
                status: 'success',
                variant: 'left-accent',
                position: 'top-right',
            });

            onClose();
        } catch (error) {
            toast({
                title: 'Uh Oh',
                description: 'An error occurred while updating personnel.',
                status: 'error',
                variant: 'left-accent',
                position: 'top-right',
            });
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    }

    return (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Edit Personnel</DrawerHeader>

                <DrawerBody>
                    <form
                        onSubmit={handleSubmit}
                        style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
                    >
                        <Box display="flex" justifyContent="center" mb={4}>
                            <FormLabel htmlFor="personnelImage">
                                <UserAvatar
                                    bg={imagePreviewUrl}
                                    name={`${personnel.firstName} ${personnel.lastName}`}
                                    id={personnel.uid}
                                    size="xl"
                                    trade={trade as LeadTrade}
                                />
                            </FormLabel>
                        </Box>

                        <VStack spacing={2} mb={6}>
                            <FormControl isRequired>
                                <FormLabel fontSize="sm">Badge Number</FormLabel>
                                <Input
                                    type="number"
                                    placeholder="Badge Number"
                                    value={badgeNumber}
                                    onChange={({ currentTarget }) =>
                                        setBadgeNumber(currentTarget.value)
                                    }
                                />
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel fontSize="sm">Trade</FormLabel>
                                <Select
                                    placeholder="Select Trade"
                                    value={trade}
                                    onChange={({ currentTarget }) =>
                                        setTrade(currentTarget.value)
                                    }
                                >
                                    {Object.values(LeadTrade).map((trade) => (
                                        <option key={trade} value={trade}>
                                            {`${trade.charAt(0).toUpperCase()}${trade.slice(1)}`}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel fontSize="sm">Rate</FormLabel>
                                <Select
                                    placeholder="Select Rate"
                                    value={rate}
                                    onChange={({ currentTarget }) => setRate(currentTarget.value)}
                                >
                                    {Object.values(PersonnelRate).map((rate) => (
                                        <option key={rate} value={rate}>
                                            {`${rate.charAt(0).toUpperCase()}${rate.slice(1)}`}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        </VStack>

                        <Box
                            display="flex"
                            gap={3}
                            justifyContent="space-between"
                            marginTop="auto"
                            pb={4}
                        >
                            <Button
                                variant="secondary"
                                onClick={onClose}
                                disabled={isSubmitting}
                                mr={2}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="primary"
                                isLoading={isSubmitting}
                            >
                                Save Personnel
                            </Button>
                        </Box>
                    </form>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
}
