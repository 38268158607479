import { ITenantProfile, IUser } from "../types";
import { DocumentSnapshot, QueryDocumentSnapshot } from "firebase/firestore";

export function transformDocToUser(doc: DocumentSnapshot, tenantProfileDocs: QueryDocumentSnapshot[] | null): IUser | null {
  const id = doc.id;
  const data = doc.data() as IUser;

  // Ensure tenantIds is available and not empty
  if (!data.tenantIds || data.tenantIds.length === 0) {
    console.warn("No tenantIds found for user:", id);
    return null;
  }

  // TODO: Set to checked-out tenant (based on token claim)
  const tenantId = data.tenantIds[0]; // This should be replaced with the actual logic to get the checked-out tenant ID
  const tenantProfileDoc = tenantProfileDocs?.find(doc => doc.id === tenantId);

  // Ensure tenantProfileDoc exists
  if (!tenantProfileDoc || !tenantProfileDoc.exists()) {
    console.warn(`No tenant profile found for tenant ID: ${tenantId}`);
    return {
      uid: id,
      ...data,
      tenantId,
      tenantProfile: null // Handle the absence of tenant profile
    };
  }

  const tenantProfile: ITenantProfile = {
    id: tenantProfileDoc.id,
    ...tenantProfileDoc.data() as ITenantProfile
  };

  return {
    uid: id,
    ...data,
    tenantId,
    tenantProfile
  };
}
