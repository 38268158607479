import React from 'react';
import { Button } from '@chakra-ui/react';
import { MdHelpOutline } from 'react-icons/md';  


interface SupportFABProps {
  onOpen: () => void;  
}

const SupportFAB: React.FC<SupportFABProps> = ({ onOpen }) => {
  return (
    <Button
      colorScheme="blue"
      variant="ghost" 
      size="md"  
      onClick={onOpen}
      position="fixed"
      bottom="4"
      right="4"
      rounded="full"
      boxShadow="lg"
      leftIcon={<MdHelpOutline size="1.5em" />}  
    >
    </Button> 
  );
};

export default SupportFAB;
