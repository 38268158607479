import { Box, Text } from '@chakra-ui/react';
import { Personnel } from '../../assets';
import { LeadTrade } from '../../types';
import { TRADE_COLORS } from '../../constants';

interface IProps {
  trade: LeadTrade;
}

export default function TradeBadge({ trade }: IProps): JSX.Element {
  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Personnel color={TRADE_COLORS[trade]} />

      <Text textTransform="capitalize">{trade}</Text>
    </Box>
  );
}
