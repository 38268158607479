import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetUser } from '../../contexts/UserContext';
import { usePageError } from '../../contexts/PageErrorContext';

interface IProps {
  children: React.ReactNode;
  requiredRole?: string; // Optional prop if you want to check for specific roles
}

export default function ProtectedRoute({ children, requiredRole }: IProps): JSX.Element {
  const user = useGetUser();
  const navigate = useNavigate();
  const { setError } = usePageError(); // Use the setError function from the context

  React.useEffect(() => {
    // If the user is not logged in
    if (!user) {
      setError(new Error('You must be logged in to view this page.'));
      navigate('/login');
      return;
    }

    // If a required role is specified and the user does not have that role
    if (requiredRole && user.role !== requiredRole) {
      setError(new Error('You do not have permission to view this page.'));
      navigate('/unauthorized');
      return;
    }

    // If the user is logged in and no role checking is required, clear any existing errors
    setError(null);

  }, [user, navigate, setError, requiredRole]);

  // If the user is not logged in, render nothing or a loading spinner
  if (!user) {
    return <div>Loading...</div>; // or your loading component
  }

  // If the user exists and no error has been set, render the children components
  return <>{children}</>;
}
