import React from 'react';
import { Select, FormControl, FormLabel } from '@chakra-ui/react';
import { RoleDropdownProps } from '../../types';

const RoleDropdown: React.FC<RoleDropdownProps> = ({
  label,
  value,
  onChange,
  isEditable,
  roles
}) => {
  return (
    <FormControl id="role" mb={4} isDisabled={!isEditable}>
      <FormLabel>{label}</FormLabel>
      <Select name="role" value={value} onChange={onChange} disabled={!isEditable}>
        {roles.map((roleOption) => (
          <option key={roleOption} value={roleOption}>
            {roleOption}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default RoleDropdown;