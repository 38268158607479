import {
  Box,
  Button,
  Tab,
  Tabs,
  TabPanel,
  TabPanels,
  TabList,
  useDisclosure,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { EditWorkItemForm, Loader } from '../../../components';
import { Edit } from '../../../assets';
import Breadcrumbs from './Breadcrumbs';
import PersonnelTab from './PersonnelTab';
import ResourcesTab from './ResourcesTab';
import WorkBreakdownTab from './WorkBreakdownTab';
import { useSubscribeToWorkItem } from '../../../hooks';

export default function WorkItemPage(): JSX.Element {
  const { id: projectId, workItemId } = useParams();
  const [workItem, isLoading] = useSubscribeToWorkItem(workItemId);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        mb={6}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Breadcrumbs
          projectId={projectId}
          workItemNumber={workItem ? workItem.number : ''}
        />

        {workItem && (
          <Button
            leftIcon={<Edit />}
            size="sm"
            variant="outline"
            onClick={onOpen}
          >
            Edit Work Item
          </Button>
        )}
      </Box>

      {workItem && (
        <EditWorkItemForm
          workItem={workItem}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}

      {isLoading ? (
        <Box>
          <Loader />
        </Box>
      ) : (
        <Box bg="white" rounded="sm" shadow="md" p={4}>
          <Tabs colorScheme="black">
            <TabList borderBottom="none">
              <Tab fontSize="sm" fontWeight="medium">
                Work Breakdown
              </Tab>
              <Tab fontSize="sm" fontWeight="medium">
                Resources
              </Tab>
              <Tab fontSize="sm" fontWeight="medium">
                Personnel
              </Tab>
            </TabList>

            {workItem && (
              <TabPanels>
                <TabPanel>
                  <WorkBreakdownTab workItem={workItem} />
                </TabPanel>
                <TabPanel>
                  <ResourcesTab workItem={workItem} />
                </TabPanel>
                <TabPanel>
                  <PersonnelTab workItem={workItem} />
                </TabPanel>
              </TabPanels>
            )}
          </Tabs>
        </Box>
      )}
    </>
  );
}
