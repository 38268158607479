import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { IPersonnel } from '../types';
import useFetchPersonnel from './useFetchPersonnel';

export default function useFetchPersonnelByProject(projectId: string): [IPersonnel[], boolean, string | undefined] {
  const [personnel, isLoading, error] = useFetchPersonnel();
  const [projectTeam, setProjectTeam] = useState<IPersonnel[]>([]);

  useEffect(() => {
    if (!projectId) return;
    const filteredTeam = personnel.filter(
      personnel => Array.isArray(personnel.projects) && personnel.projects.includes(projectId)
    );
    setProjectTeam(filteredTeam);
  }, [personnel, projectId]);

  return [projectTeam, isLoading, error];
}
