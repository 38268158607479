import { Box, Image } from '@chakra-ui/react';
import { Routes, Route, useParams } from 'react-router-dom';
import ProjectTeam from './ProjectTeam';
import WorkItemsList from './WorkItemsList';
import WorkItem from './WorkItem';
import { PageLayout, ProjectSidebar } from '../../components';
import washingtonsHammerLogo from '../../assets/washingtons-hammer-logo.svg';

export default function ProjectPage(): JSX.Element {
  const { id } = useParams();

  return (
    <PageLayout hasSidebar>
      <Box display="grid" gridTemplateColumns="250px auto">
        <ProjectSidebar projectId={id} />

        <Box
          py={4}
          px={6}
          display="grid"
          gridTemplateRows="min-content auto min-content"
        >
          <Routes>
            <Route index element={<WorkItemsList />} />
            <Route path="team" element={<ProjectTeam />} />
            <Route path="items/:workItemId" element={<WorkItem />} />
          </Routes>

          <Box pt={4}>
            <Image src={washingtonsHammerLogo} margin="auto" maxW={9} />
          </Box>
        </Box>
      </Box>
    </PageLayout>
  );
}
