import { LeadTrade } from './types';

export const TRADE_COLORS: Record<LeadTrade, string> = {
  [LeadTrade.ELECTRICIAN]: '#E53E3E',
  [LeadTrade.FIREWATCH]: '#F69F1B',
  [LeadTrade.INSULATOR]: '#DA4EE7',
  [LeadTrade.MACHINIST]: '#413EFB',
  [LeadTrade.PAINTER]: '#4ECBE7',
  [LeadTrade.PIPEFITTER]: '#5FBE68',
  [LeadTrade.RIGGER]: '#FBDC3E',
  [LeadTrade.SCAFFOLDING_BUILDER]: '#850BC5',
  [LeadTrade.SHEETMETAL]: '#8F8F8F',
  [LeadTrade.SHIPFITTER]: '#5F5F61',
  [LeadTrade.WELDER]: '#000',
};

export const ROLES = ['tenantAdmin', 'personnel', ];