import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Container,
  Heading,
  Select,
  Alert,
  AlertIcon,
  AlertDescription
} from '@chakra-ui/react';
import { RoleDropdownProps } from '../../types';
import { useGetUser } from '../../contexts/UserContext';
import { ProfileSettingsProps } from '../../types';
import RoleDropdown from '../RoleDropdown';
import { ROLES } from '../../constants';





const ProfileSettings: React.FC<ProfileSettingsProps> = ({ isEditable }) => {
  const user = useGetUser();
  const [profile, setProfile] = useState({
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    role: user?.role || 'personnel', // Default to 'personnel'
    // Add any other fields that you have in your IUser type that are in the document
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (!isEditable) return; // Prevent edits if the user is not allowed to edit
    const { name, value } = e.target;
    setProfile(prevState => ({ ...prevState, [name]: value }));
  };

  const handleUpdateProfile = () => {
    if (!isEditable) return; // Prevent updates if the user is not allowed to edit
    console.log('Update profile logic here', profile);
  };


  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setProfile(prevState => ({ ...prevState, role: value }));
  };

  return (
    <Container maxW="1000px" py={4}>
      <Heading as="h2" size="lg" mb={6}>
        Profile
      </Heading>
      {!isEditable && (
        <Alert status="info" mb={6}>
          <AlertIcon />
          <AlertDescription>
            If you need to update your profile information, please contact your admin.
          </AlertDescription>
        </Alert>
      )}
      <Box>
        {/* Other form controls... */}
        <FormControl id="firstName" mb={4} isReadOnly={!isEditable}>
          <FormLabel>First Name</FormLabel>
          <Input
            type="text"
            name="firstName"
            value={profile.firstName}
            onChange={handleInputChange}
            isReadOnly={!isEditable}
          />
        </FormControl>
        <FormControl id="lastName" mb={4} isReadOnly={!isEditable}>
          <FormLabel>Last Name</FormLabel>
          <Input
            type="text"
            name="lastName"
            value={profile.lastName}
            onChange={handleInputChange}
            isReadOnly={!isEditable}
          />
        </FormControl>
        <FormControl id="email" mb={4} isReadOnly>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            name="email"
            value={profile.email}
            isReadOnly
          />
        </FormControl>
        <RoleDropdown
          label="Role"
          value={profile.role} // The selected role from the profile state.
          onChange={handleRoleChange} // Function to handle changes in role.
          isEditable={isEditable} // If the dropdown should be editable.
          roles={ROLES} // The list of roles for the dropdown options.
        />
      </Box>
    </Container>
  );
};

export default ProfileSettings;
